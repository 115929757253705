import React from "react";
import configs from "./configs.json";

export default class UserList extends React.Component {
  render() {
    const { list, rSpan, pairIndex } = this.props;
    return (
      <>
        <tr>
          <td width="20%">{list[0]}</td>
          <td width="20%">{list[1]}</td>
          {rSpan === 0 ? (
            <td
              style={{ marginLeft: 50 }}
              rowSpan="3"
              id={`p${pairIndex || 1}`}
            ></td>
          ) : null}
          {/* <td>{list[2]}</td>
        <td>{list[3]}</td>
        <td>{list[4]}</td> */}
        </tr>
        {rSpan === 2 ? (
          <tr>
            <td>&nbsp;</td>
          </tr>
        ) : null}
      </>
    );
  }
}

import React from "react";

export default class Cards extends React.Component {
  state = {
    userRubric: this.props.userRubric,
  };

  componentWillUpdate() {
    const element = document.getElementsByClassName("checkbox");

    for (let kk = 0; kk < element.length; kk++) {
      element[kk].checked = false;
      console.log(element[kk].checked);
    }
    // document.getElementById("checkbox00").checked = false;
  }
  render() {
    return (
      <div
        className="accordion"
        id="accordionExample"
        style={{ overflow: "scroll", height: "500px" }}
      >
        <ul className="list-group">
          {this.state.userRubric.map((r, n) => {
            return (
              <div>
                <li
                  key={n}
                  style={{ fontSize: "12.5px" }}
                  className="list-group-item list-group-item list-group-item-action active d-flex justify-content-between align-items-center mt-3"
                >
                  {r.title}
                </li>
                {r.rates.map((rate, i) => {
                  return (
                    <li
                      className={`list-group-item d-flex justify-content-between align-items-center sl-opt${n}`}
                      key={i}
                      id={`sl-opt${i}${n}`}
                    >
                      <span style={{ fontSize: "11.5px" }}>
                        <div
                          className={`badge ${
                            rate.rate == 4
                              ? "badge-success"
                              : rate.rate == 3
                              ? "badge-info"
                              : rate.rate == 2
                              ? "badge-warning"
                              : "badge-danger"
                          }`}
                        >
                          {rate.value}
                        </div>
                        <br />
                        {rate.notes}
                      </span>
                      <span className="badge badge-info badge-pill">
                        <input
                          id={`checkbox${n}${i}`}
                          className="checkbox"
                          type="radio"
                          name={`r${n}`}
                          value={rate.rate}
                          defaultChecked={this.state.userRubric[n].value}
                          checked={this.state.userRubric[n].value == rate.rate}
                          onChange={(e) => {
                            const t = [...this.state.userRubric];
                            t[n].value = e.target.value;
                            this.setState({ userRubric: t });
                            const element = document.getElementsByClassName(
                              "sl-opt" + n
                            );
                            // element.map((e) =>
                            //   e.classList.remove("bg-success")
                            // );
                            for (let kk = 0; kk < element.length; kk++) {
                              element[kk].classList.remove("sl-opt-add");
                            }
                            const eee = document.getElementById(
                              "sl-opt" + i + n
                            );
                            eee.classList.add("sl-opt-add");
                          }}
                        />
                      </span>
                    </li>
                  );
                })}
              </div>
            );
          })}
        </ul>
      </div>
    );
  }
}

import React from "react";
import CSVReader from "react-csv-reader";
import UserList from "./UserList";
import axios from "axios";
import configs from "./configs.json";

class Admin extends React.Component {
  count = 0;
  obj = [];
  constructor() {
    super();
    this.state = {
      UserList: [],
      fileInfo: null,
      randomList: [],
      pairdList: [],
      withWork: [],
    };
  }

  parings() {
    let s = [...this.state.UserList.slice(1)];
    s = s.sort(function (a, b) {
      return 0.5 - Math.random();
    });
    this.setState({ randomList: s });
  }

  saveData() {
    axios.post(configs.API_URL + "/pairings", this.state.UserList);
  }

  distribute() {
    const limit = Math.ceil(this.obj.length);
    let iCount = 1;
    let iIndex = 0;
    console.log(this.obj.length, this.state.randomList.length, limit);
    let temp = [...this.state.withWork];
    while (temp.length > 0) {
      const d = temp[iIndex];
      const ll = document.getElementById("p" + iCount);
      const bl = this.obj[iCount - 1].find(
        (item) => parseInt(item.uid) === parseInt(d[5])
      );
      if (bl) {
        iIndex++;
        continue;
      }
      this.obj[iCount - 1].map((o) => o.peerId.push(d[5]));
      ll.innerHTML = ll.innerHTML + "<div>" + d[0] + "</div>";
      iCount++;
      if (iCount > limit) {
        iCount = 1;
      }
      temp.splice(iIndex, 1);
      iIndex = 0;
    }
    console.log(this.obj);
    // this.state.withWork.map((list, i) => {
    //   // console.log(iCount);
    //   const ll = document.getElementById("p" + iCount);
    //   ll.innerHTML = ll.innerHTML + "<div>" + list[0] + "</div>";
    //   iCount++;
    //   if (iCount === limit) {
    //     iCount = 1;
    //   }
    // });
  }

  saveParings() {
    axios.post(configs.API_URL + "/pairings", this.obj);
  }

  saveUsers() {
    axios.post(configs.API_URL + "/users", this.state.UserList);
  }

  render() {
    this.count = 0;
    return (
      <div className="container" style={{ marginTop: 50, marginBottom: 50 }}>
        <h3>Peer Review</h3>
        <button onClick={this.parings.bind(this)}>Pair</button>
        <button onClick={this.distribute.bind(this)}>Distribute</button>
        <button onClick={this.saveParings.bind(this)}>Save Parings</button>
        <button onClick={this.saveUsers.bind(this)}>Save Users</button>
        <CSVReader
          onFileLoaded={(data, fileInfo) => {
            const s = data.filter((l) => l[3] === "1");
            this.setState({ UserList: data, fileInfo, withWork: s });
          }}
        />
        {this.state.randomList.length > 0 ? (
          <table>
            <tbody>
              {this.state.randomList.map((list, index) => {
                if (index % 3 === 0) {
                  this.count++;
                }
                if (this.obj[this.count - 1]) {
                  this.obj[this.count - 1].push({
                    uid: list[5],
                    name: list[0],
                    peerId: [],
                  });
                } else {
                  this.obj[this.count - 1] = [
                    { uid: list[5], name: list[0], peerId: [] },
                  ];
                }
                return (
                  <UserList
                    list={list}
                    key={index}
                    rSpan={index % 3}
                    pairIndex={this.count}
                  />
                );
              })}
            </tbody>
          </table>
        ) : (
          <table>
            <tbody>
              {this.state.UserList.map((list, index) => (
                <UserList list={list} key={index} pairIndex={20} />
              ))}
            </tbody>
          </table>
        )}
      </div>
    );
  }
}

export default Admin;

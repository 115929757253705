import React from "react";
import configs from "./configs.json";

export default class Login extends React.Component {
  state = {
    uid: "",
    statusMsg: "",
    statusColor: "",
    found: false,
  };

  submitID(e) {
    e.preventDefault();
    if (this.state.uid.trim().length <= 0) {
      this.setState({ statusMsg: "Type in your login code" });
      setTimeout(() => this.setState({ statusMsg: "" }), 3500);
      return;
    }

    this.setState({
      statusMsg: "Please wait...",
      statusColor: "blue",
    });
    fetch(configs.API_URL + "/users")
      .then((r) => r.json())
      .then((rs) => {
        // console.log(rs);
        rs.map((r) => {
          r.map((n) => {
            if (n[6] === this.state.uid) {
              this.setState({ found: true });
              this.props.login(n);
            }
          });
        });
        if (this.state.found == false) {
          this.setState({
            statusMsg: "Invalid code",
            statusColor: "red",
          });
          setTimeout(() => this.setState({ statusMsg: "" }), 3500);
        }
      });
  }
  render() {
    return (
      <div>
        <div className="row" style={{ marginTop: 50 }}>
          <div className="col-sm-12 text-center">
            <div
              className="alert alert-info"
              style={{ marginLeft: 10, marginRight: 10 }}
            >
              ATTENTION: This peer review should only be done on a laptop for
              effective user experience
            </div>
          </div>
        </div>
        <div className="wrapper fadeInDown" style={{ marginTop: 80 }}>
          <div id="formContent">
            <div className="fadeIn first">
              <img
                src="https://azubiafrica.org/wp-content/uploads/2020/05/AzubiAfrica-Logo-1.png"
                id="icon"
                alt="User Icon"
              />
            </div>

            <form onSubmit={this.submitID.bind(this)}>
              <input
                type="password"
                id="password"
                className="fadeIn third"
                name="login"
                placeholder="Enter your pin code"
                value={this.state.uid}
                onChange={(e) => this.setState({ uid: e.target.value })}
              />
              <input type="submit" className="fadeIn fourth" value="Log In" />
              <div
                style={{
                  color: this.state.statusColor,
                  marginBottom: 30,
                }}
              >
                {this.state.statusMsg}
              </div>
            </form>
          </div>
        </div>
      </div>
    );
  }
}

import React from "react";
import Students from "./Students";
import Admin from "./Admin";
import Login from "./Login";

class App extends React.Component {
  state = {
    login: false,
    userInfo: "",
  };

  login(userInfo) {
    this.setState({ login: true, userInfo });
  }
  render() {
    if (!this.state.login) return <Login login={this.login.bind(this)} />;
    if (this.state.userInfo[4] === "student") {
      return <Students userInfo={this.state.userInfo} />;
    } else {
      return <Admin />;
    }
  }
}

export default App;

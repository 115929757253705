import React from "react";
import axios from "axios";
import configs from "./configs.json";
// import Popup from "reactjs-popup";
import Modal from "react-modal";
import Cards from "./Cards";

let peer = 0;
let totalPeers = 0;

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
};

Modal.setAppElement("#root");
class RubricsScores extends React.Component {
  constructor() {
    super();
    this.state = {
      peer: 0,
      title: "",
      score: 0,
      rubrics: [],
      userRubric: [],
      tmp: 0,
      value: [],
      submitStatus: "",
      modalIsOpen: false,
      modalMsg: "Your have submmited review for Peer",
      subMsg: "Please click on the next peer to continue ",
      count: 0,
    };
  }

  componentDidMount() {
    fetch(configs.API_URL + "/rubrics")
      .then((r) => r.json())
      .then((rs) => {
        this.setState({ rubrics: rs });
      });
    // document.getElementById("checkbox00").checked = false;
  }
  setRating(v) {
    this.setState({ score: v });
  }

  cards(btnPeerID) {
    return (
      <div
        className="accordion"
        id="accordionExample"
        style={{ overflow: "scroll", height: "500px" }}
      >
        <ul className="list-group">
          {this.state.userRubric.map((r, n) => {
            return (
              <div>
                <li
                  key={n}
                  style={{ fontSize: "12.5px" }}
                  className="list-group-item list-group-item list-group-item-action active d-flex justify-content-between align-items-center mt-3"
                >
                  {r.title}
                </li>
                {r.rates.map((rate, i) => {
                  return (
                    <li
                      className={`list-group-item d-flex justify-content-between align-items-center sl-opt${n}`}
                      key={i}
                      id={`sl-opt${i}${n}`}
                    >
                      <span style={{ fontSize: "11.5px" }}>
                        <div
                          className={`badge ${
                            rate.rate == 4
                              ? "badge-success"
                              : rate.rate == 3
                              ? "badge-info"
                              : rate.rate == 2
                              ? "badge-warning"
                              : "badge-danger"
                          }`}
                        >
                          {rate.value}
                        </div>
                        <br />
                        {rate.notes}
                      </span>
                      <span className="badge badge-info badge-pill">
                        <input
                          type="radio"
                          name={`r${n}`}
                          id={`checkbox${n}${i}`}
                          value={btnPeerID}
                          // defaultChecked={this.state.userRubric[n].value}
                          checked={this.state.userRubric[n].value == btnPeerID}
                          onChange={(e) => {
                            const t = [...this.state.userRubric];
                            t[n].value = e.target.value;
                            this.setState({ userRubric: t });
                            console.log(this.state.userRubric[n]);
                            const element = document.getElementsByClassName(
                              "sl-opt" + n
                            );
                            // element.map((e) =>
                            //   e.classList.remove("bg-success")
                            // );
                            for (let kk = 0; kk < element.length; kk++) {
                              element[kk].classList.remove("sl-opt-add");
                            }
                            const eee = document.getElementById(
                              "sl-opt" + i + n
                            );
                            eee.classList.add("sl-opt-add");
                          }}
                        />
                      </span>
                    </li>
                  );
                })}
              </div>
            );
          })}
        </ul>
      </div>
    );
  }

  openModal() {
    // setIsOpen(true);
  }

  closeModal() {
    this.setState({ modalIsOpen: false });
  }

  render() {
    const { currentPeerID, btnPeerID } = this.props;
    if (currentPeerID !== this.state.tmp) {
      // this.setState({ userRubric: [] });
      this.setState({
        userRubric: [...this.state.rubrics],
        tmp: currentPeerID,
      });
    }
    return (
      <>
        <div className="row">
          <div className="col-sm-12 col-md-10 mt-4"></div>
          {this.state.userRubric.length > 0 ? (
            <div className="col-sm-12 col-md-2">
              <div
                className="list-group float-right"
                style={{
                  position: "absolute",
                  zIndex: 999,
                  backgroundColor: "#fff",
                }}
              >
                <a
                  href="#."
                  className="list-group-item list-group-item-action flex-column align-items-start active"
                >
                  <div className="d-flex w-100 justify-content-between">
                    <h5 className="mb-1">RUBRICS</h5>
                  </div>
                  <p className="mb-1">
                    Use the provided rubrics helpers to rate each peer
                  </p>
                </a>
                {/* <Cards userRubric={this.state.userRubric} /> */}
                {this.cards(btnPeerID + 1)}
                <div className="alert alert-info">
                  {this.state.submitStatus}
                </div>
                {console.log("Some length", this.state.rubrics.length)}
                {this.state.rubrics.length > 0 ? (
                  <button
                    className="btn btn-info btn-lg mt-3"
                    onClick={() => {
                      this.setState({
                        submitStatus: "Please wait...",
                      });
                      axios
                        .post(configs.API_URL + "/scores", {
                          peer: currentPeerID,
                          scores: this.state.userRubric,
                        })
                        .then((e) => {
                          const str = "pbtn" + currentPeerID;
                          console.log(str);
                          document.getElementById(str).disabled = true;
                          this.setState({
                            submitStatus:
                              "Rate submited for Peer" + currentPeerID,
                            count: this.state.count + 1,
                            modalIsOpen: true,
                            currentReviewMsg: "",
                          });
                          if (this.state.count == totalPeers) {
                            this.setState({
                              modalMsg: "Thank you for submitting your reviews",
                              modalIsOpen: true,
                              subMsg: "",
                            });
                          }
                          setTimeout(() => {
                            this.setState({
                              submitStatus: "",
                              userRubric: [],
                              fileLink: "",
                              currentReviewMsg: "",
                            });
                          }, 3500);
                        })
                        .catch((e) => console.log("error", e));
                    }}
                  >
                    Submit When Done
                  </button>
                ) : null}
              </div>
            </div>
          ) : null}
        </div>
        <Modal
          isOpen={this.state.modalIsOpen}
          style={customStyles}
          contentLabel="Example Modal"
        >
          <h3>{this.state.modalMsg}</h3>
          <span>{this.state.subMsg}</span> &nbsp;
          <button
            className="btn btn-primary btn-sm"
            onClick={this.closeModal.bind(this)}
          >
            close
          </button>
        </Modal>
      </>
    );
  }
}

export default class Students extends React.Component {
  state = {
    fileLink: "",
    peers: [],
    statusMsg: "",
    currentPeerID: 0,
    statusColor: "",
    currentReviewMsg: "",
    btnPeerID: 0,
  };

  componentDidMount() {
    fetch(configs.API_URL + "/pairings/")
      .then((r) => r.json())
      .then((rs) => {
        rs.map((r) => {
          r.map((s) => {
            if (s.uid == this.props.userInfo[5]) {
              this.setState({ peers: s });
              totalPeers = s.peerId.length;
              console.log("Total", totalPeers);
            }
          });
        });

        fetch(configs.API_URL + "/scores/")
          .then((r) => r.json())
          .then((rs) => {
            rs.map((r) => {
              const el = document.getElementById("pbtn" + r.peer);
              if (el && r.userId == this.props.userInfo[5]) {
                console.log(r, this.props.userInfo[5]);
                el.disabled = true;
              }
            });
          });
      });
  }

  render() {
    return (
      <div className="containers" style={{ margin: 30 }}>
        <h1>Welcome: {this.state.peers.name}</h1>
        <div style={{ marginBottom: 25 }} className="col-md-7">
          <strong>Step 1 :</strong> Click on peer button to load assigned peer's
          work. <br />
          <strong>Step 2 :</strong> Read through your peer's loaded work, and
          assign appropriate score based on rubric categories on the right.
          Select the check box on the option that corresponds to the score you
          want to assign to each rubric category. (Scroll down on rubic section
          to see all categories) <br />
          <strong>Step 3 :</strong> Click on submit button below rubric only
          after you have selected a score from each rubric category. <br />
          <strong>Step 4 :</strong> Click on the other peer button to evaluate
          next peer's work (repeat steps 2 to 3), until you have exhausted all
          peer buttons. <br />
          <div className="mt-2">
            Please note: You will not be able to edit an already submitted
            review.
          </div>
          <div className="mt-2">
            Contact Wendel Laryea or James Asafuah if you are facing any
            challenges with this.
          </div>
        </div>
        {this.state.peers.peerId &&
          this.state.peers.peerId.map((p, i) => {
            return (
              <>
                <button
                  className="btn btn-primary"
                  style={{ width: "200px" }}
                  key={i}
                  id={`pbtn${p}`}
                  onClick={() => {
                    this.setState({
                      statusMsg: "Please wait...",
                      fileLink: "",
                      statusColor: "blue",
                    });
                    peer = i;
                    fetch(`/files/${p}.pdf`)
                      .then((r) => r.text())
                      .then((rs) => {
                        if (rs.indexOf(".wrapper {") < 0) {
                          this.setState({
                            fileLink: `/files/${p}.pdf`,
                            currentPeerID: p,
                            statusMsg: "",
                            currentReviewMsg: `CURRENTLY REVIEWING PEER ${
                              i + 1
                            }`,
                          });
                        } else {
                          this.setState({
                            statusMsg:
                              "File not found. Please notify facilitator",
                            fileLink: "",
                            statusColor: "red",
                            currentReviewMsg: "",
                          });
                          setTimeout(
                            () => this.setState({ statusMsg: "" }),
                            3500
                          );
                        }
                      })
                      .catch((e) => {
                        this.setState({
                          statusMsg:
                            "File not found. Please notify facilitator",
                          statusColor: "red",
                          currentReviewMsg: "",
                        });
                        setTimeout(
                          () => this.setState({ statusMsg: "" }),
                          3500
                        );
                      });
                  }}
                >
                  Peer {i + 1}
                </button>{" "}
              </>
            );
          })}{" "}
        <RubricsScores
          currentPeerID={this.state.currentPeerID}
          btnPeerID={this.state.btnPeerID}
        />
        <div style={{ color: this.state.statusColor }}>
          {this.state.statusMsg}
        </div>
        <h4>{this.state.currentReviewMsg}</h4>
        <hr />
        <embed
          src={this.state.fileLink}
          width="980"
          height="630"
          type="application/pdf"
          style={{ marginLeft: 30 }}
        />
      </div>
    );
  }
}
